@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before, .mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

@font-face {
  font-family: "Cera-Pro";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../fonts/CeraPro/CeraPro-Light.woff") format("woff2");
}

@font-face {
  font-family: "Cera-Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/CeraPro/CeraPro-Regular.woff") format("woff2");
}

@font-face {
  font-family: "Cera-Pro";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/CeraPro/CeraPro-Medium.woff") format("woff2");
}

@font-face {
  font-family: "Cera-Pro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/CeraPro/CeraPro-Bold.woff") format("woff2");
}

* {
  box-sizing: border-box;
  border: none;
}

a {
  text-decoration: none;
  color: #000;
  outline-color: transparent;
  background-color: transparent;
}

a:hover {
  text-decoration: none;
}

button {
  outline: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

img {
  display: block;
  height: auto;
}

p {
  padding: 0;
  margin: 0;
}

input, textarea {
  margin: 0;
  outline-color: transparent;
  outline: none;
  font-family: Cera-Pro, Arial, Helvetica, sans-serif;
}

input:focus::placeholder, textarea:focus::placeholder {
  color: transparent;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0 0 0 0);
}

body {
  margin: 0px;
  font-family: Cera-Pro, Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  width: 100%;
  color: #0f0f0f;
  background-color: #ffffff;
  outline-color: transparent;
}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  background-color: #ffffff;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #56bdff;
}

textarea {
  resize: vertical;
}

main {
  display: block;
}

section {
  width: 100%;
  margin: 0 auto;
}

.wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.button {
  display: inline-block;
  text-align: center;
  font-size: 13px;
  line-height: 13px;
  border: 2px solid #ffffff;
  padding: 16px 30px;
  border-radius: 23px;
  cursor: pointer;
  color: #ffffff;
  text-transform: uppercase;
}

.h2 {
  margin: 0;
  color: #313131;
  font-size: 36px;
  line-height: 36px;
}

.hidden {
  overflow: hidden;
}

@media screen and (max-width: 1240px) {
  .wrapper {
    max-width: 768px;
    padding: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    max-width: 360px;
  }
  .h2 {
    font-size: 26px;
    line-height: 26px;
    font-weight: 200;
    text-align: center;
  }
}

.logo {
  display: grid;
  justify-content: center;
  align-items: center;
}

.logo__text {
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}

.logo__image {
  justify-self: center;
}

.header__content {
  padding: 13px 0 13px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation__list {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 40px;
}

.navigation__link {
  color: #000000;
  font-size: 15px;
  line-height: 15px;
  font-weight: bold;
  transition: 0.4s;
}

.navigation__link:hover {
  color: #95d321;
  transition: 0.4s;
}

.navigation__link--green {
  color: #95d321;
}

.navigation__link--green:hover {
  text-decoration: underline;
}

.header__cabinet {
  padding: 10px 20px;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  border-radius: 17px;
  background-color: #56bdff;
  transition: 0.4s;
}

.header__cabinet:hover {
  transition: 0.4s;
  background-color: #1d92dc;
}

.logo__image--mobile {
  display: none;
}

.header__mobile-menu {
  display: none;
}

.drop-navigation {
  position: absolute;
  width: 100%;
  z-index: 3;
  left: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #ffffff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: hidden;
  bottom: 150%;
  box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.1);
  transition: 0.4s;
}

.drop-navigation--active {
  display: block;
  cursor: auto;
  top: 100%;
  bottom: auto;
  transition: 0.4s;
}

.overlay {
  display: none;
  background: rgba(0, 0, 0, 0.35);
  width: 100%;
  height: 1600px;
  position: absolute;
  z-index: 6;
  left: 0;
  top: 300px;
  pointer-events: none;
}

.overlay--active {
  display: block;
}

.drop-navigation__wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.navigation__item--toggle {
  cursor: pointer;
  transition: 0.3s;
  border-bottom: 2px solid transparent;
}

.navigation__item--active {
  color: #95d321;
  border-bottom: 2px solid #95d321;
  transition: 0.3s;
}

.header {
  position: relative;
  z-index: 10;
}

.drop-navigation__wrapper {
  padding: 60px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.drop-navigation__title {
  font-size: 34px;
  line-height: 44px;
  margin-bottom: 30px;
  color: #313131;
}

.drop-navigation__left {
  padding-right: 40px;
}

.drop-navigation__middle {
  padding-left: 30px;
  padding-right: 30px;
}

.drop-navigation__description {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
  color: #313131;
}

.drop-navigation__link {
  background-color: #95d321;
  max-width: 230px;
  width: 100%;
}

.drop-list {
  display: grid;
  grid-gap: 5px;
}

.drop-list__link {
  padding: 10px 10px 10px 40px;
  display: block;
  font-weight: 500;
  border-radius: 20px;
  transition: 0.2s;
  position: relative;
  color: #313131;
}

.drop-list__link::before {
  content: '';
  width: 4px;
  height: 4px;
  left: 15px;
  top: 50%;
  position: absolute;
  margin-top: -2px;
  border-top: 2px solid;
  border-right: 2px solid;
  display: block;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.drop-list__link:hover {
  background-color: #56bdff;
  color: #ffffff;
  transition: 0.2s;
}

.drop-slide__title {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 10px;
  color: #313131;
}

.drop-slide__description {
  font-size: 13px;
  line-height: 20px;
  color: #313131;
  margin-bottom: 20px;
}

.drop-navigation__slider {
  max-width: 375px;
  width: 100%;
}

.drop-slide {
  height: 270px;
  background-color: rgba(86, 189, 255, 0.4);
  padding: 20px;
  border-radius: 15px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

.drop-slide__link {
  padding: 9px 30px;
  border-color: #95d321;
  transition: 0.4s;
}

.drop-slide__link:hover {
  background-color: #95d321;
  transition: 0.4s;
}

@media screen and (max-width: 1240px) {
  .drop-navigation--active {
    display: none !important;
  }
  .navigation__item--active {
    all: unset;
  }
  .navigation__item--toggle {
    all: unset;
  }
  .overlay {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .logo__image--mobile {
    display: flex;
  }
  .logo__image--desktop {
    display: none;
  }
  .header__navigation--desktop {
    display: none;
  }
  .logo__text {
    display: none;
  }
  .header__content {
    padding: 14px 0;
    justify-content: space-around;
    align-items: start;
  }
  .logo {
    justify-content: start;
  }
  .logo__image {
    margin-bottom: 0;
  }
  .header__cabinet {
    padding: 10px 22px;
    font-size: 10px;
    line-height: 10px;
  }
  .mobile-menu__pay {
    display: flex;
    justify-content: center;
    padding: 10px 22px;
    font-size: 10px;
    line-height: 10px;
    border-radius: 27px;
    background-color: #56bdff;
    color: #fff;
    text-align: center;
    font-weight: 700;
    background-color: #95d321;
  }
  .header__cabinet--desktop {
    display: none;
  }
  .header__mobile-button {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 10px;
    border-radius: 50%;
    background-color: #56bdff;
    color: #fff;
    font-weight: 700;
    text-align: center;
  }
  body {
    position: relative;
  }
  .mobile-menu {
    display: block;
    background-color: #ffffff;
    position: absolute;
    top: -100%;
    height: 0;
    opacity: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    transition: 1.5s;
  }
  .mobile-menu--active {
    top: 0;
    height: 100%;
    opacity: 1;
    transition: 1.5s;
  }
  .mobile-menu__item {
    color: #ffffff;
    font-size: 20px;
    line-height: 28px;
    text-transform: uppercase;
  }
  .mobile-menu__content {
    padding: 20px;
    display: grid;
    grid-gap: 12px;
    justify-content: start;
    position: relative;
    background-color: #fff;
    z-index: 9;
  }
  .navigation {
    order: -1;
  }
  .navigation__list {
    grid-template-columns: 1fr;
    grid-gap: 15px;
    grid-auto-flow: row;
  }
  .header__mobile-button {
    display: flex;
  }
  .header__mobile-button--close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

@media screen and (max-width: 1240px) and (min-width: 768px) {
  .header__content {
    flex-direction: column;
  }
  .navigation__link {
    font-size: 12px;
    line-height: 12px;
  }
  .header__logo {
    margin-bottom: 10px;
  }
  .header__cabinet {
    margin-top: 20px;
  }
}

.subheader {
  background-color: #313131;
  color: #ffffff;
  z-index: 10;
  position: relative;
}

.subheader__content {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-subheader {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
}

.list-subheader__item {
  padding-right: 20px;
  position: relative;
}

.list-subheader__item::after {
  content: "";
  width: 1px;
  height: 100%;
  background-color: #404040;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.list-subheader__item:last-child {
  padding-right: 0;
}

.list-subheader__item:last-child::after {
  display: none;
}

.list-subheader__link {
  padding: 6px 0;
  color: #ffffff;
  font-size: 13px;
  line-height: 13px;
  display: block;
  transition: 0.4s;
}

.list-subheader__link:hover {
  color: #95d321;
  transition: 0.4s;
}

.subheader__contacts {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  align-items: center;
}

.contact-subheader {
  display: flex;
  padding-right: 20px;
  position: relative;
  align-items: center;
}

.contact-subheader::after {
  content: "";
  width: 1px;
  height: 100%;
  background-color: #404040;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.contact-subheader:last-child {
  padding-right: 0;
}

.contact-subheader:last-child::after {
  display: none;
}

.contact-subheader__title {
  margin-right: 15px;
  font-size: 13px;
  line-height: 13px;
  color: rgba(255, 255, 255, 0.4);
  font-weight: 200;
}

.contact-subheader__icon {
  margin-right: 10px;
  opacity: 0.2;
}

.contact-subheader__link {
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
  transition: 0.4s;
}

.contact-subheader__link:hover {
  color: #95d321;
  transition: 0.4s;
}

.pay__balance {
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
  margin-right: 4px;
}

.subheader__pay {
  display: flex;
  align-items: center;
}

.pay__icon {
  margin-right: 10px;
  font-size: 15px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.4);
}

.pay__link {
  font-size: 13px;
  line-height: 13px;
  color: #95d321;
  transition: 0.4s;
}

.pay__link:hover {
  text-decoration: underline;
  transition: 0.4s;
}

@media screen and (max-width: 1240px) and (min-width: 768px) {
  .subheader__content {
    display: grid;
    grid-gap: 10px;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
  }
  .subheader__left {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .subheader__middle {
    grid-column-start: 1;
    grid-column-end: 3;
    order: -1;
  }
  .subheader__contacts {
    justify-content: center;
  }
  .list-subheader {
    grid-template-columns: max-content max-content;
  }
  .subheader__pay {
    justify-content: flex-end;
  }
  .subheader__right {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .list-subheader__link {
    font-size: 12px;
    line-height: 12px;
  }
}

@media screen and (max-width: 767px) {
  .subheader__left {
    display: none;
  }
  .subheader__right {
    display: none;
  }
  .contact-subheader__title {
    font-size: 10px;
    line-height: 10px;
    text-align: center;
  }
  .contact-subheader {
    display: grid;
    grid-gap: 2px;
    padding-left: 30px;
  }
  .contacts__tel {
    text-align: center;
    font-size: 11px;
    line-height: 11px;
  }
  .subheader__content {
    display: grid;
    grid-template-columns: 1fr;
  }
  .subheader {
    position: relative;
  }
  .contact-subheader__icon {
    left: 0;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
  }
}

.mainImage {
  background: #6f2eff;
  background: linear-gradient(180deg, #6f2eff 0%, #56bdff 100%);
  position: relative;
}

.slide-mainImage {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  min-height: 460px;
  position: relative;
}

.slide-mainImage__left {
  padding-top: 75px;
  padding-bottom: 60px;
}

.slide-mainImage__right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-mainImage__title {
  margin: 0 0 50px 0;
  position: relative;
  z-index: 2;
  max-width: 510px;
  font-size: 72px;
  line-height: 72px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.slide-mainImage__image {
  max-width: 400px;
}

.slide-mainImage__description {
  position: relative;
  z-index: 2;
  font-size: 20px;
  line-height: 20px;
  max-width: 530px;
  margin-bottom: 55px;
  color: #ffffff;
  font-weight: 700;
}

.slide-mainImage__button {
  position: relative;
  z-index: 2;
}

.slick-dots {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-auto-flow: column;
  grid-gap: 18px;
}

.slick-dots li {
  display: flex;
  width: 10px;
  height: 10px;
  overflow: hidden;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
}

.slick-dots li button {
  padding: 3px;
  width: 10px;
  height: 10px;
  font-size: 0;
  line-height: 0;
  color: transparent;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
}

.slick-dots .slick-active {
  background-color: #fff;
}

.slick-dots .slick-active button {
  background-color: #fff;
}

@media screen and (max-width: 1240px) and (min-width: 768px) {
  .slide-mainImage__title {
    font-size: 55px;
    line-height: 65px;
  }
}

@media screen and (max-width: 767px) {
  .slide-mainImage {
    min-height: 300px;
    position: relative;
    grid-template-columns: 1fr;
  }
  .slide-mainImage:nth-child(2n) {
    margin-left: 2px;
  }
  .slide-mainImage:nth-child(3n) {
    margin-left: 2px;
  }
  .slide-mainImage:nth-child(4n) {
    margin-left: 2px;
  }
  .slide-mainImage__left {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
  }
  .slide-mainImage__title {
    margin-bottom: 12px;
    font-size: 34px;
    line-height: 34px;
    text-align: center;
  }
  .slide-mainImage__description {
    font-size: 12px;
    line-height: 12px;
    margin: 0 auto 15px auto;
    text-align: center;
    max-width: 165px;
  }
  .slide-mainImage__button {
    font-size: 8px;
    line-height: 8px;
    padding: 8px 18px;
    margin: 0 auto;
    background-color: #56bdff;
    border: none;
  }
  .slide-mainImage__right {
    position: absolute;
    right: 2px;
    bottom: 2px;
  }
  .slide-mainImage__image {
    max-width: 150px;
  }
  .slick-dots {
    bottom: 19px;
  }
}

.news__content {
  padding: 55px 0 75px 0;
}

.news__top {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  margin-bottom: 50px;
}

.news__to-all {
  padding: 0px 55px 0px 55px;
  font-size: 17px;
  line-height: 25px;
  color: #56bdff;
  transition: 0.4s;
  position: relative;
}

.news__to-all:hover {
  transition: 0.4s;
  text-decoration: underline;
}

.news__to-all::after, .news__to-all::before {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0' y='0' width='15' height='15' viewBox='0 0 284.9 284.9' xml:space='preserve' fill='%23b8b8b8' enable-background='new 0 0 284.9 284.9'%3E%3Cpath d='M222.7 135.9l-133-133A9 9 0 0083 0a9 9 0 00-6.6 2.9L62.2 17a9 9 0 000 13.2l112.2 112.2L62.2 254.7a9 9 0 000 13.1l14.3 14.3a9 9 0 0013.2 0l133-133a9 9 0 000-13.2z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
}

.news__to-all::after {
  right: 0;
}

.news__to-all::before {
  left: 0;
  transform: translateY(-50%) rotateZ(180deg);
}

.articles-news {
  display: grid;
  grid-template-columns: 520px 1fr 1fr;
  grid-gap: 16px 24px;
}

.article {
  padding: 30px 23px 45px 23px;
  border-radius: 3px;
  border: 1px solid #dbdbdb;
}

.article--main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  border: none;
  border-radius: 5px;
  background: #56bdff;
  grid-row-start: 1;
  grid-row-end: 3;
  background: linear-gradient(180deg, #56bdff 0%, #6f2eff 100%);
  background-size: cover;
  background-repeat: no-repeat;
}

.article--main .article__title {
  color: #ffffff;
  font-size: 32px;
  line-height: 37px;
  text-transform: uppercase;
}

.article--main .article__description {
  color: rgba(255, 255, 255, 0.6);
}

.article--main .article__date {
  position: absolute;
  top: 30px;
  left: 23px;
}

.article__date {
  margin-bottom: 25px;
  padding: 8px 15px;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  border-radius: 14px;
  background-color: #95d321;
  text-align: center;
  display: inline-block;
}

.article__title {
  display: block;
  color: #000000;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 20px;
}

.article__description {
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.news__image--mobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .news__content {
    padding: 25px 12px 25px 12px;
  }
  .news__to-all {
    display: none;
  }
  .news__top {
    margin-bottom: 20px;
  }
  .news__image--mobile {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
  .article {
    padding: 15px;
  }
  .article__date {
    margin-bottom: 20px;
    font-size: 11px;
    line-height: 11px;
    padding: 4px 10px;
    top: 15px;
    left: 15px;
  }
  .news--section .article--main {
    display: none !important;
  }
  .articles-news {
    grid-template-columns: 1fr;
  }
  .article__title {
    font-size: 17px;
    line-height: 17px;
    margin-bottom: 15px;
  }
  .article--main {
    padding-top: 60px;
  }
  .article--main .article__date {
    left: 15px;
    top: 15px;
  }
  .article--main .article__title {
    font-size: 19px;
    line-height: 19px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1240px) and (min-width: 768px) {
  .articles-news {
    grid-template-columns: 1fr 1fr;
  }
}

.about__wrapper {
  padding: 80px 0 90px 0;
}

.about__content {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 520px 1fr;
}

.about__left {
  border-radius: 5px;
  overflow: hidden;
}

.about__title {
  margin-bottom: 50px;
  font-weight: normal;
}

.about__right {
  padding-top: 35px;
  position: relative;
}

.about__right::after {
  content: "М";
  position: absolute;
  bottom: 15px;
  right: 90px;
  font-size: 362px;
  line-height: 312px;
  font-weight: bold;
  color: rgba(86, 189, 255, 0.13);
  pointer-events: none;
}

.about__subtitle {
  margin-bottom: 50px;
  font-size: 27px;
  line-height: 27px;
  color: #000000;
}

.about__subtitle span {
  text-transform: uppercase;
  display: block;
  font-size: 36px;
  line-height: 36px;
  font-weight: 700;
}

.about__text {
  font-size: 15px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.about__text p {
  margin-bottom: 20px;
}

.about__text p:last-child {
  margin-bottom: 0;
}

.about__subtitle--mobile {
  display: none;
}

@media screen and (max-width: 1240px) and (min-width: 768px) {
  .about__content {
    grid-template-columns: 1fr;
  }
  .about__wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .about__title {
    display: none;
  }
  .about__wrapper {
    padding: 30px 20px 0 20px;
  }
  .about__content {
    grid-template-columns: 1fr;
    grid-gap: 0;
    padding: 0 0 25px 0;
  }
  .about__subtitle--desktop {
    display: none;
  }
  .about__subtitle--mobile {
    display: block;
  }
  .about__image {
    max-width: 300px;
    margin: 0 auto 25px auto;
  }
  .about__subtitle {
    margin-bottom: 18px;
    text-align: center;
    font-size: 18px;
    line-height: 18px;
  }
  .about__subtitle span {
    font-size: 24px;
    line-height: 24px;
  }
  .about__right {
    padding-top: 0;
  }
  .about__right::after {
    display: none;
  }
  .about__text p {
    font-size: 10px;
    line-height: 10px;
    margin-bottom: 15px;
  }
}

.request {
  background-color: #56bdff;
  background-repeat: no-repeat;
  background-position: right center;
}

.request__content {
  padding: 60px 0 55px 0;
  max-width: 870px;
}

.request__title {
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.request__description {
  margin-bottom: 25px;
  color: #ffffff;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
}

.form__input {
  padding: 15px 20px;
  background-color: transparent;
  font-size: 14px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.76);
  border: 2px solid rgba(255, 255, 255, 0.76);
  border-radius: 4px;
}

.form__input::placeholder {
  color: rgba(255, 255, 255, 0.76);
}

.form__button {
  font-size: 14px;
  line-height: 14px;
  color: #121212;
  font-weight: bold;
  padding: 15px 47px;
  border: none;
  background-color: #ffffff;
}

.form {
  display: grid;
  grid-gap: 13px;
  grid-template-columns: 300px 300px max-content;
}

@media screen and (max-width: 1240px) and (min-width: 768px) {
  .form {
    grid-template-columns: 1fr 1fr 0.5fr;
  }
}

@media screen and (max-width: 767px) {
  .form {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .form__button {
    justify-self: center;
    font-size: 9px;
    line-height: 9px;
    padding: 10px 30px;
  }
  .request__content {
    padding: 250px 0 15px 0;
  }
  .request--section {
    background-position: top center;
  }
  .request--section .request__content {
    padding-top: 250px;
  }
  .request {
    background-size: 100% 290px;
    background-position: top right;
  }
  .request .request__content {
    padding-top: 320px;
  }
  .request__title {
    font-size: 27px;
    line-height: 27px;
    text-align: center;
    margin-bottom: 15px;
  }
  .request__description {
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
  }
  .form__input {
    padding: 8px 14px;
    font-size: 9px;
    line-height: 9px;
  }
}

@media screen and (max-width: 535px) {
  .request {
    background-size: contain;
  }
  .request .request__content {
    padding-top: 240px;
  }
  .request--section {
    background-size: contain;
  }
  .request--section .request__content {
    padding-top: 280px;
  }
}

@media screen and (max-width: 400px) {
  .request .request__content {
    padding-top: 180px;
  }
  .request--section {
    background-size: contain;
  }
  .request--section .request__content {
    padding-top: 220px;
  }
}

.subfooter {
  background-color: #313131;
  border-top: 1px solid #3b3b3b;
}

.subfooter__content {
  padding: 21px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subfooter__copyright {
  font-size: 14px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.55);
}

.subfooter__socials {
  display: grid;
  grid-gap: 10px;
  grid-auto-flow: column;
  align-items: center;
}

.socials__link {
  display: flex;
  align-items: center;
  filter: grayscale(100%);
}

.footer {
  background-color: #313131;
}

.footer__content {
  display: flex;
  justify-content: space-between;
  padding: 35px 0 25px 0;
}

.footer__links {
  margin-right: 100px;
  flex-shrink: 0;
  width: 100%;
  max-width: 745px;
  display: flex;
  justify-content: space-between;
}

.footer__links li:first-child a {
  font-weight: 700;
  cursor: default;
}

.footer__links a {
  font-size: 15px;
  line-height: 30px;
  color: #ffffff;
}

.contacts__connect {
  margin-bottom: 8px;
  font-size: 13px;
  line-height: 13px;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.4);
  transition: 0.4s;
}

.contacts__connect:hover {
  color: #95d321;
  transition: 0.4s;
}

.contacts__tel {
  font-size: 27px;
  line-height: 27px;
  color: #ffffff;
  transition: 0.4s;
  margin-bottom: 20px;
}

.contacts__tel:hover {
  transition: 0.4s;
  color: #95d321;
}

.contacts__search {
  height: 55px;
  width: 100%;
  padding-left: 25px;
  padding-right: 50px;
  margin-bottom: 30px;
  border-radius: 10px;
  font-size: 15px;
  line-height: 15px;
  background-color: #484848;
  color: #ffffff;
}

.contacts__search::placeholder {
  color: #ffffff;
}

.contacts__search::-webkit-search-decoration, .contacts__search::-webkit-search-cancel-button, .contacts__search::-webkit-search-results-button,
.contacts__search ::-webkit-search-results-decoration {
  display: none;
}

.contacts__mail {
  color: #95d321;
  font-size: 13px;
  line-height: 13px;
  text-decoration: underline;
}

.contacts__mail:hover {
  text-decoration: none;
}

.search__wrapper {
  position: relative;
}

.search__icon {
  position: absolute;
  right: 35px;
  bottom: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.footer__contacts {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.footer__contacts--mobile {
  display: none;
}

@media screen and (max-width: 1240px) and (min-width: 768px) {
  .footer__content {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr;
  }
  .footer__links {
    margin-right: 0;
  }
  .footer__contacts {
    align-items: flex-start;
  }
}

@media screen and (max-width: 767px) {
  .contacts__search {
    display: none;
  }
  .footer__links {
    margin-right: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px 40px;
  }
  .footer__contacts--mobile {
    display: flex;
    padding-top: 7px;
  }
  .footer__contacts--desktop {
    display: none;
  }
  .footer__links li a {
    font-size: 10px;
    line-height: 10px;
    font-weight: 200;
  }
  .footer__links:first-child a {
    font-weight: 400;
  }
  .footer__links .contacts__connect {
    color: #999999;
    margin-bottom: 5px;
    font-size: 8px;
    line-height: 8px;
  }
  .footer__links .contacts__tel {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 6px;
    text-align: left;
  }
  .footer__links .contacts__mail {
    font-size: 8px;
    line-height: 8px;
    color: #95d321;
    font-weight: 200;
  }
  .subfooter__content {
    padding: 20px 0 10px 0;
    display: grid;
    grid-gap: 15px;
    justify-content: center;
  }
  .socials {
    order: -1;
    grid-gap: 4px;
  }
  .social__item {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.8);
  }
  .subfooter__copyright {
    font-size: 8px;
    line-height: 8px;
    font-weight: 200;
  }
}

.hot {
  background-color: #f0f8ff;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
}

.hot::before {
  content: "";
  height: 165px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 0;
}

.hot__content {
  padding: 90px 0 85px 0;
  position: relative;
  z-index: 1;
}

.hot__title {
  text-align: center;
  margin-bottom: 65px;
  font-weight: 700;
  font-size: 45px;
  line-height: 45px;
  letter-spacing: 2px;
  color: #313131;
  text-transform: uppercase;
}

.rate__close {
  display: none;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 4;
  cursor: pointer;
}

.rate__close--active {
  display: flex;
}

.rate {
  overflow: hidden;
  position: relative;
  display: grid !important;
  grid-template-columns: 1fr;
  justify-content: space-between;
  max-width: 350px;
  min-height: 533px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-left: 10px solid #95d321;
  transition: 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation: fadeInFromNone 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

.rate:hover {
  transform: rotateY(0deg) translateY(-10px);
}

.rate__rotate {
  transition: 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-style: preserve-3d;
  transform: rotateY(-180deg);
  animation: fadeIn 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  border-left-width: 0px;
}

.rate__rotate:hover {
  transform: rotateY(-180deg) translateY(0px);
}

.rate__rotate .rate__top, .rate__rotate .rate__middle, .rate__rotate .rate__bottom {
  transform: rotateY(-180deg);
  transition: 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation: fadeInFromNone 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

@keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.rate__icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #95d321;
}

.rate__top {
  display: grid;
  align-self: start;
  grid-gap: 10px;
  grid-template-columns: max-content 1fr;
  align-items: center;
  padding: 22px 20px 30px 20px;
  border-bottom: 1px solid #f0f0f0;
}

.rate__title {
  display: grid;
  justify-content: center;
}

.rate__label {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.37);
  font-size: 14px;
  line-height: 14px;
}

.rate__bottom {
  display: grid;
  align-self: end;
}

.rate__name {
  font-size: 28px;
  line-height: 28px;
  color: #313131;
  text-transform: uppercase;
  font-weight: bold;
}

.total {
  margin: 0 -20px 30px -20px;
  padding: 15px 30px 15px 30px;
  background-color: #ededed;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form__button {
  width: 100%;
}

.total__title {
  font-size: 14px;
  line-height: 14px;
  color: #8e9595;
}

.total__number {
  font-size: 36px;
  line-height: 36px;
  color: #313131;
}

.total__number span {
  font-size: 18px;
  line-height: 18px;
}

.policy {
  text-align: center;
  color: rgba(0, 0, 0, 0.33);
}

.policy a {
  color: rgba(0, 0, 0, 0.33);
}

.policy a:hover {
  color: #000000;
}

.rate__middle {
  min-height: 180px;
  display: grid;
  grid-gap: 30px;
  align-items: center;
  padding: 15px 20px 10px 20px;
}

.rate__price {
  margin-bottom: 25px;
  font-size: 48px;
  line-height: 48px;
  color: #313131;
  text-align: center;
}

.rate__price span {
  font-size: 18px;
  line-height: 18px;
}

.rate__button {
  align-self: end;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 15px 20px;
  background-color: #95d321;
  text-align: center;
  border-radius: 23px;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.rate__present {
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.33);
}

.option-rate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.option-rate__middle {
  width: 100%;
}

.option-rate__input {
  margin-right: 10px;
}

.option-rate__right {
  flex-shrink: 0;
}

.option-rate__price {
  font-size: 32px;
  line-height: 32px;
  color: #313131;
}

.option-rate__left {
  display: flex;
}

.option-rate__input {
  cursor: pointer;
}

.option-rate__title {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 4px;
  color: #313131;
  font-weight: 700;
}

.option-rate__subtitle {
  font-size: 13px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.33);
}

.speed {
  padding: 15px 0 10px 0;
  background-color: #ededed;
}

.speed__limit {
  margin-bottom: 10px;
  font-size: 48px;
  line-height: 48px;
  color: #313131;
  text-align: center;
}

.speed__limit span {
  font-size: 18px;
  line-height: 18px;
}

.speed__label {
  color: #8e9595;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
}

.rate__more {
  display: flex;
  align-items: center;
  padding: 0 30px 0 20px;
  height: 70px;
  position: relative;
}

.rate__more::after {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0' y='0' width='15' height='15' viewBox='0 0 284.9 284.9' xml:space='preserve' fill='%23000000' enable-background='new 0 0 284.9 284.9'%3E%3Cpath d='M222.7 135.9l-133-133A9 9 0 0083 0a9 9 0 00-6.6 2.9L62.2 17a9 9 0 000 13.2l112.2 112.2L62.2 254.7a9 9 0 000 13.1l14.3 14.3a9 9 0 0013.2 0l133-133a9 9 0 000-13.2z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.rate--green {
  border-color: #95d321;
}

.rate--green .rate__icon {
  background-color: #95d321;
}

.rate--green .rate__button, .rate--green .form__button {
  background-color: #56bdff;
  color: #ffffff;
}

.rate--green .form__input {
  border-color: #56bdff;
}

.rate--blue {
  border-color: #56bdff;
}

.rate--blue .rate__icon {
  background-color: #56bdff;
}

.rate--blue .rate__button, .rate--blue .form__button {
  background-color: #95d321;
  color: #ffffff;
}

.rate--blue .form__input {
  border-color: #95d321;
}

.rate--purple {
  border-color: #6f2eff;
}

.rate--purple .rate__icon {
  background-color: #ff9344;
}

.rate--purple .rate__button, .rate--purple .form__button {
  background-color: #6f2eff;
  color: #ffffff;
}

.rate--purple .form__input {
  border-color: #6f2eff;
}

.hot__rates {
  padding: 0 35px;
  position: relative;
}

.hot__rates .slick-arrow {
  position: absolute;
  font-size: 0;
  line-height: 0;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  top: 50%;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0' y='0' width='15' height='15' viewBox='0 0 284.9 284.9' xml:space='preserve' fill='%23adb3b8' enable-background='new 0 0 284.9 284.9'%3E%3Cpath d='M222.7 135.9l-133-133A9 9 0 0083 0a9 9 0 00-6.6 2.9L62.2 17a9 9 0 000 13.2l112.2 112.2L62.2 254.7a9 9 0 000 13.1l14.3 14.3a9 9 0 0013.2 0l133-133a9 9 0 000-13.2z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.hot__rates .slick-prev {
  left: 0;
  transform: translateY(-50%) rotateZ(180deg);
}

.hot__rates .slick-next {
  right: 20px;
}

.hot__rates .slick-track {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.hot__rates .slick-active:nth-child(3n) {
  margin-right: 3px;
}

.hot__rates .slick-active:nth-child(2n) {
  margin-right: 3px;
}

.hot__rates .slick-active:nth-child(1n) {
  margin-right: 3px;
}

.hot__rates .slick-slide {
  outline: none;
}

.rate__form {
  grid-template-columns: 1fr;
}

.rate__form .form__input {
  padding: 11px 20px;
  border-radius: 25px;
  color: rgba(0, 0, 0, 0.6);
}

.rate__form .form__input::placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.rate__form--active {
  display: grid;
}

.rate__option {
  margin-bottom: 20px;
}

.rate__option:last-child {
  margin-bottom: 0;
}

.step-2 {
  display: none;
}

.step-3 {
  display: none;
}

@media screen and (max-width: 1240px) and (min-width: 768px) {
  .hot__rates {
    padding: 0 10px;
  }
  .hot__rates .slick-next {
    right: -10px;
  }
  .hot__rates .slick-prev {
    left: -10px;
  }
  .rate {
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }
}

@media screen and (max-width: 767px) {
  .hot__content {
    padding: 30px 0 45px 0;
  }
  .hot__title {
    font-size: 27px;
    line-height: 27px;
    margin-bottom: 35px;
    text-align: center;
  }
  .rate__icon {
    width: 55px;
    height: 55px;
  }
  .rate__icon img {
    transform: scale(0.76);
  }
  .rate__label {
    font-size: 10px;
    line-height: 10px;
  }
  .rate__name {
    font-size: 20px;
    line-height: 20px;
  }
  .hot__rates {
    padding: 0 10px;
  }
  .hot__rates .slick-next {
    right: -15px;
  }
  .hot__rates .slick-prev {
    left: -15px;
  }
  .rate__top {
    padding: 15px 20px 20px 40px;
    grid-gap: 20px;
    width: 100%;
  }
  .rate__bottom {
    width: 100%;
  }
  .rate__title {
    justify-content: start;
  }
  .rate {
    border-width: 6px;
    justify-content: start;
    display: flex !important;
    flex-direction: column;
    min-height: 400px;
  }
  .total {
    margin: 0 0 20px 0;
    width: 100%;
  }
  .option-rate {
    width: 100%;
  }
  .rate__middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 20px 20px 10px 20px;
  }
  .rate__form {
    margin-top: 0;
  }
  .rate__price {
    font-size: 34px;
    line-height: 34px;
    margin-bottom: 15px;
  }
  .rate__price span {
    font-size: 13px;
    line-height: 13px;
  }
  .rate__button {
    width: 100%;
    padding: 10px 20px;
    max-width: 90%;
    margin: 0 auto 10px auto;
    font-size: 10px;
    line-height: 10px;
  }
  .rate__name {
    font-size: 15px !important;
    line-height: 15px !important;
  }
  .rate__present {
    font-size: 10px;
    line-height: 10px;
  }
  .rate__form {
    width: 100%;
  }
  .rate__form .form__button {
    width: 100%;
    padding: 13px 20px;
  }
  .speed {
    padding: 12px 20px 10px 20px;
  }
  .speed__limit {
    font-size: 34px;
    line-height: 34px;
    margin-bottom: 10px;
  }
  .speed__limit span {
    font-size: 13px;
    line-height: 13px;
  }
  .rate__more {
    height: 50px;
    font-size: 10px;
    line-height: 10px;
  }
  .hot::before {
    height: 105px;
  }
  .step-2 {
    display: none;
  }
  .step-3 {
    display: none;
  }
}

.business {
  background-image: url("../img/business/business__background.webp");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
  background-color: #728191;
}

.business__content {
  padding: 85px 0 70px 0;
  max-width: 900px;
}

.business__title {
  margin-bottom: 25px;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 72px;
  line-height: 72px;
  letter-spacing: 1px;
}

.business__text {
  max-width: 600px;
  color: #ffffff;
  margin-bottom: 40px;
}

.business__text p {
  margin-bottom: 15px;
}

.business__text p:last-child {
  margin-bottom: 0;
}

.business__button {
  background-color: #95d321;
  border: none;
}

@media screen and (max-width: 767px) {
  .business__title {
    font-size: 38px;
    line-height: 38px;
    margin-bottom: 30px;
    color: #000000;
  }
  .business {
    background-size: cover;
    background-position: right center;
  }
  .business__content {
    padding: 60px 0 40px 0;
  }
  .business__text {
    margin-bottom: 25px;
    font-size: 12px;
    line-height: 12px;
    color: #000000;
  }
  .business__button {
    padding: 10px 22px;
    font-size: 10px;
    line-height: 10px;
  }
}

.popular__content {
  padding: 90px 0;
}

.popular {
  border-bottom: 1px solid #e8e8e8;
}

.benefits {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
}

.item-benefits {
  height: 470px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: #728191;
  border-radius: 4px;
  padding: 24px 40px;
  display: grid;
  align-content: flex-end;
  justify-content: start;
}

.item-benefits__title {
  margin-bottom: 15px;
  font-size: 30px;
  line-height: 30px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
}

.item-benefits__title span {
  color: #f6e029;
}

.item-benefits__text {
  margin-bottom: 30px;
  color: #ffffff;
  font-size: 14px;
  line-height: 14px;
  max-width: 300px;
}

.item-benefits__button {
  padding: 14px 35px;
  background-color: #95d321;
  border: none;
  justify-self: flex-start;
}

.popular__title {
  margin-bottom: 85px;
  font-size: 45px;
  line-height: 45px;
  font-weight: 700;
  color: #313131;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .popular__content {
    padding: 40px 0;
  }
  .popular__title {
    margin-bottom: 40px;
    text-align: center;
    font-size: 27px;
    line-height: 27px;
  }
  .benefits {
    grid-template-columns: 1fr;
  }
  .item-benefits {
    height: 350px;
    padding: 20px 25px;
  }
  .item-benefits__title {
    font-size: 21px;
    line-height: 21px;
    margin-bottom: 10px;
  }
  .item-benefits__text {
    margin-bottom: 20px;
    font-size: 10px;
    line-height: 10px;
  }
  .item-benefits__button {
    padding: 10px 22px;
    font-size: 10px;
    line-height: 10px;
  }
}
