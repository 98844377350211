.logo {
    display: grid;
    justify-content: center;
    align-items: center; }

.logo__text {
    color: rgba($Black, 0.45);
    text-align: center; }

.logo__image {
    justify-self: center; }

.header__content {
    padding: 13px 0 13px 60px;
    display: flex;
    align-items: center;
    justify-content: space-between; }

.navigation__list {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 40px; }

.navigation__link {
    color: $Black;
    font-size: 15px;
    line-height: 15px;
    font-weight: bold;
    transition: 0.4s;

    &:hover {
        color: $LightGreen;
        transition: 0.4s; } }

.navigation__link--green {
    color: $LightGreen;

    &:hover {
        text-decoration: underline; } }

.header__cabinet {
    padding: 10px 20px;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: $White;
    font-weight: bold;
    border-radius: 17px;
    background-color: $Blue;
    transition: 0.4s;

    &:hover {
        transition: 0.4s;
        background-color: #1d92dc; } }

.logo__image--mobile {
    display: none; }

.header__mobile-menu {
    display: none; }

.drop-navigation {
    position: absolute;
    width: 100%;
    z-index: 3;
    left: 0;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: $White;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
    bottom: 150%;
    box-shadow: inset 0 10px 10px -10px rgba(0,0,0,0.10);
    transition: 0.4s; }

.drop-navigation--active {
    display: block;
    cursor: auto;
    top: 100%;
    bottom: auto;
    transition: 0.4s; }

.overlay {
    display: none;
    background: rgba(0,0,0,0.35);
    width: 100%;
    height: 1600px;
    position: absolute;
    z-index: 6;
    left: 0;
    top: 300px;
    pointer-events: none; }

.overlay--active {
    display: block; }

.drop-navigation__wrapper {
    max-width: 1200px;
    margin: 0 auto; }

.navigation__item--toggle {
    cursor: pointer;
    transition: 0.3s;
    border-bottom: 2px solid transparent; }
.navigation__item--active {
    color: $LightGreen;
    border-bottom: 2px solid $LightGreen;
    transition: 0.3s; }

.header {
    position: relative;
    z-index: 10; }

.drop-navigation__wrapper {
    padding: 60px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; }

.drop-navigation__title {
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 30px;
    color: #313131; }

.drop-navigation__left {
    padding-right: 40px; }

.drop-navigation__middle {
    padding-left: 30px;
    padding-right: 30px; }

.drop-navigation__description {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
    color: #313131; }

.drop-navigation__link {
    background-color: $LightGreen;
    max-width: 230px;
    width: 100%; }

.drop-list {
    display: grid;
    grid-gap: 5px; }

.drop-list__link {
    padding: 10px 10px 10px 40px;
    display: block;
    font-weight: 500;
    border-radius: 20px;
    transition: 0.2s;
    position: relative;
    color: #313131;

    &::before {
        content: '';
        width: 4px;
        height: 4px;
        left: 15px;
        top: 50%;
        position: absolute;
        margin-top: -2px;
        border-top: 2px solid;
        border-right: 2px solid;
        display: block;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg); }

    &:hover {
        background-color: $Blue;
        color: $White;
        transition: 0.2s; } }

.drop-slide__title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
    color: #313131; }

.drop-slide__description {
    font-size: 13px;
    line-height: 20px;
    color: #313131;
    margin-bottom: 20px; }

.drop-navigation__slider {
    max-width: 375px;
    width: 100%; }

.drop-slide {
    height: 270px;
    background-color: rgba($Blue, 0.4);
    padding: 20px;
    border-radius: 15px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover; }

.drop-slide__link {
    padding: 9px 30px;
    border-color: $LightGreen;
    transition: 0.4s;

    &:hover {
        background-color: $LightGreen;
        transition: 0.4s; } }

@media screen and ( max-width: 1240px  ) {

    .drop-navigation--active {
        display: none !important; }

    .navigation__item--active {
        all: unset; }

    .navigation__item--toggle {
        all: unset; }

    .overlay {
        display: none; } }

@media screen and ( max-width: 767px  ) {

    .logo__image--mobile {
        display: flex; }

    .logo__image--desktop {
        display: none; }

    .header__navigation--desktop {
        display: none; }

    .logo__text {
        display: none; }

    .header__content {
        padding: 14px 0;
        justify-content: space-around;
        align-items: start; }

    .logo {
        justify-content: start; }

    .logo__image {
        margin-bottom: 0; }

    .header__cabinet {
        padding: 10px 22px;
        font-size: 10px;
        line-height: 10px; }

    .mobile-menu__pay {
        display: flex;
        justify-content: center;
        padding: 10px 22px;
        font-size: 10px;
        line-height: 10px;
        border-radius: 27px;
        background-color: #56bdff;
        color: #fff;
        text-align: center;
        font-weight: 700;
        background-color: $LightGreen; }

    .header__cabinet--desktop {
        display: none; }

    .header__mobile-button {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        line-height: 10px;
        border-radius: 50%;
        background-color: #56bdff;
        color: #fff;
        font-weight: 700;
        text-align: center; }

    body {
        position: relative; }

    .mobile-menu {
        display: block;
        background-color: $White;
        position: absolute;
        top: -100%;
        height: 0;
        opacity: 0;
        left: 0;
        z-index: 99;
        width: 100%;
        transition: 1.5s; }

    .mobile-menu--active {
        top: 0;
        height: 100%;
        opacity: 1;
        transition: 1.5s; }

    .mobile-menu__item {
        color: $White;
        font-size: 20px;
        line-height: 28px;
        text-transform: uppercase; }

    .mobile-menu__content {
        padding: 20px;
        display: grid;
        grid-gap: 12px;
        justify-content: start;
        position: relative;
        background-color: #fff;
        z-index: 9; }

    .navigation {
        order: -1; }

    .navigation__list {
        grid-template-columns: 1fr;
        grid-gap: 15px;
        grid-auto-flow: row; }

    .header__mobile-button {
        display: flex; }

    .header__mobile-button--close {
        position: absolute;
        right: 20px;
        top: 20px; } }


@media screen and ( max-width: 1240px ) and (min-width: 768px) {
    .header__content {
        flex-direction: column; }

    .navigation__link {
        font-size: 12px;
        line-height: 12px; }

    .header__logo {
        margin-bottom: 10px; }

    .header__cabinet {
        margin-top: 20px; } }
