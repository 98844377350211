// --------- DEFUALT STYLES ---------------

* {
	box-sizing: border-box;
	border: none; }

a {
	text-decoration: none;
	color: #000;
	outline-color: transparent;
	background-color: transparent;

	&:hover {
		text-decoration: none; } }

button {
	outline: none; }

ul {
	padding: 0;
	margin: 0;
	list-style: none; }

img {
	display: block;
	height: auto; }

p {
	padding: 0;
	margin: 0; }

input, textarea {
	margin: 0;
	outline-color: transparent;
	outline: none;
	font-family: Cera-Pro, Arial, Helvetica, sans-serif;

	&:focus {
		&::placeholder {
			color: transparent; } } }

.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	clip: rect(0 0 0 0); }

body {
	margin: 0px;
	font-family: Cera-Pro, Arial, Helvetica, sans-serif;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	width: 100%;
	color: #0f0f0f;
	background-color: #ffffff;
	outline-color: transparent;

	&::-webkit-scrollbar {
		width: 7px; }

	&::-webkit-scrollbar-track {
		background-color: $White; }

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: $Blue; } }

textarea {
	resize: vertical; }

main {
	display: block; }

section {
	width: 100%;
	margin: 0 auto; }

.wrapper {
	max-width: 1200px;
	width: 100%;
	margin: 0 auto; }

.button {
	display: inline-block;
	text-align: center;
	font-size: 13px;
	line-height: 13px;
	border: 2px solid $White;
	padding: 16px 30px;
	border-radius: 23px;
	cursor: pointer;
	color: $White;
	text-transform: uppercase; }

.h2 {
	margin: 0;
	color: #313131;
	font-size: 36px;
	line-height: 36px; }

.hidden {
	overflow: hidden; }

@media screen and ( max-width: 1240px ) {

	.wrapper {
		max-width: 768px;
		padding: 0 20px; } }

@media screen and ( max-width: 767px ) {

	.wrapper {
		max-width: 360px; }

	.h2 {
		font-size: 26px;
		line-height: 26px;
		font-weight: 200;
		text-align: center; } }

