.news__content {
    padding: 55px 0 75px 0; }

.news__top {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    margin-bottom: 50px; }

.news__to-all {
    padding: 0px 55px 0px 55px;
    font-size: 17px;
    line-height: 25px;
    color: $Blue;
    transition: 0.4s;
    position: relative;

    &:hover {
        transition: 0.4s;
        text-decoration: underline; }

    &::after, &::before {
        content: "";
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0' y='0' width='15' height='15' viewBox='0 0 284.9 284.9' xml:space='preserve' fill='%23b8b8b8' enable-background='new 0 0 284.9 284.9'%3E%3Cpath d='M222.7 135.9l-133-133A9 9 0 0083 0a9 9 0 00-6.6 2.9L62.2 17a9 9 0 000 13.2l112.2 112.2L62.2 254.7a9 9 0 000 13.1l14.3 14.3a9 9 0 0013.2 0l133-133a9 9 0 000-13.2z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center center; }

    &::after {
        right: 0; }

    &::before {
        left: 0;
        transform: translateY(-50%) rotateZ(180deg); } }

.articles-news {
    display: grid;
    grid-template-columns: 520px 1fr 1fr;
    grid-gap: 16px 24px; }

.article {
    padding: 30px 23px 45px 23px;
    border-radius: 3px;
    border: 1px solid #dbdbdb; }

.article--main {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    border: none;
    border-radius: 5px;
    background: $Blue;
    grid-row-start: 1;
    grid-row-end: 3;
    background: linear-gradient(180deg, $Blue 0%, $Purple 100%);
    background-size: cover;
    background-repeat: no-repeat;

    .article__title {
        color: $White;
        font-size: 32px;
        line-height: 37px;
        text-transform: uppercase; }

    .article__description {
        color: rgba($White, 0.6); }

    .article__date {
        position: absolute;
        top: 30px;
        left: 23px; } }

.article__date {
    margin-bottom: 25px;
    padding: 8px 15px;
    font-size: 14px;
    line-height: 14px;
    color: $White;
    border-radius: 14px;
    background-color: $LightGreen;
    text-align: center;
    display: inline-block; }

.article__title {
    display: block;
    color: $Black;
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 20px; }

.article__description {
    font-size: 14px;
    line-height: 20px;
    color: rgba($Black, 0.6); }

.news__image--mobile {
    display: none; }

@media screen and ( max-width: 767px ) {

    .news__content {
        padding: 25px 12px 25px 12px; }

    .news__to-all {
        display: none; }

    .news__top {
        margin-bottom: 20px; }

    .news__image--mobile {
        display: block;
        width: 100%;
        margin-bottom: 20px; }

    .article {
        padding: 15px; }

    .article__date {
        margin-bottom: 20px;
        font-size: 11px;
        line-height: 11px;
        padding: 4px 10px;
        top: 15px;
        left: 15px; }

    .news--section {

        .article--main {
            display: none !important; } }

    .articles-news {
        grid-template-columns: 1fr; }

    .article__title {
        font-size: 17px;
        line-height: 17px;
        margin-bottom: 15px; }

    .article--main {
        padding-top: 60px;

        .article__date {
            left: 15px;
            top: 15px; }

        .article__title {
            font-size: 19px;
            line-height: 19px;
            margin-bottom: 20px; } } }

@media screen and ( max-width: 1240px ) and (min-width: 768px) {

    .articles-news {
        grid-template-columns: 1fr 1fr; } }
