.popular__content {
    padding: 90px 0; }

.popular {
    border-bottom: 1px solid #e8e8e8; }

.benefits {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr; }

.item-benefits {
    height: 470px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    background-color: #728191;
    border-radius: 4px;
    padding: 24px 40px;
    display: grid;
    align-content: flex-end;
    justify-content: start; }

.item-benefits__title {
    margin-bottom: 15px;
    font-size: 30px;
    line-height: 30px;
    color: $White;
    text-transform: uppercase;
    font-weight: 700;

    span {
        color: $Yellow; } }

.item-benefits__text {
    margin-bottom: 30px;
    color: $White;
    font-size: 14px;
    line-height: 14px;
    max-width: 300px; }

.item-benefits__button {
    padding: 14px 35px;
    background-color: $LightGreen;
    border: none;
    justify-self: flex-start; }

.popular__title {
    margin-bottom: 85px;
    font-size: 45px;
    line-height: 45px;
    font-weight: 700;
    color: #313131;
    text-transform: uppercase; }

@media screen and ( max-width: 767px ) {

    .popular__content {
        padding: 40px 0; }

    .popular__title {
        margin-bottom: 40px;
        text-align: center;
        font-size: 27px;
        line-height: 27px; }

    .benefits {
        grid-template-columns: 1fr; }

    .item-benefits {
        height: 350px;
        padding: 20px 25px; }

    .item-benefits__title {
        font-size: 21px;
        line-height: 21px;
        margin-bottom: 10px; }

    .item-benefits__text {
        margin-bottom: 20px;
        font-size: 10px;
        line-height: 10px; }

    .item-benefits__button {
        padding: 10px 22px;
        font-size: 10px;
        line-height: 10px; } }
