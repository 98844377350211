.business {
    background-image: url('../img/business/business__background.webp');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
    background-color: #728191; }

.business__content {
    padding: 85px 0 70px 0;
    max-width: 900px; }

.business__title {
    margin-bottom: 25px;
    color: $White;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 72px;
    line-height: 72px;
    letter-spacing: 1px; }

.business__text {
    max-width: 600px;
    color: $White;
    margin-bottom: 40px;

    p {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0; } } }

.business__button {
    background-color: $LightGreen;
    border: none; }

@media screen and ( max-width: 767px ) {

    .business__title {
        font-size: 38px;
        line-height: 38px;
        margin-bottom: 30px;
        color: $Black; }

    .business {
        background-size: cover;
        background-position: right center; }

    .business__content {
        padding: 60px 0 40px 0; }

    .business__text {
        margin-bottom: 25px;
        font-size: 12px;
        line-height: 12px;
        color: $Black; }

    .business__button {
        padding: 10px 22px;
        font-size: 10px;
        line-height: 10px; } }
