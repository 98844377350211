.subheader {
    background-color: $DarkGrey;
    color: $White;
    z-index: 10;
    position: relative; }

.subheader__content {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }

.list-subheader {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 20px; }

.list-subheader__item {
    padding-right: 20px;
    position: relative;

    &::after {
        content: "";
        width: 1px;
        height: 100%;
        background-color: #404040;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%); }

    &:last-child {
        padding-right: 0;

        &::after {
            display: none; } } }

.list-subheader__link {
    padding: 6px 0;
    color: $White;
    font-size: 13px;
    line-height: 13px;
    display: block;
    transition: 0.4s;

    &:hover {
        color: $LightGreen;
        transition: 0.4s; } }

.subheader__contacts {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 20px;
    align-items: center; }

.contact-subheader {
    display: flex;
    padding-right: 20px;
    position: relative;
    align-items: center;

    &::after {
        content: "";
        width: 1px;
        height: 100%;
        background-color: #404040;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%); }

    &:last-child {
        padding-right: 0;

        &::after {
            display: none; } } }

.contact-subheader__title {
    margin-right: 15px;
    font-size: 13px;
    line-height: 13px;
    color: rgba($White,0.4);
    font-weight: 200; }

.contact-subheader__icon {
    margin-right: 10px;
    opacity: 0.2; }

.contact-subheader__link {
    font-size: 13px;
    line-height: 13px;
    color: $White;
    transition: 0.4s;

    &:hover {
        color: $LightGreen;
        transition: 0.4s; } }

.pay__balance {
    font-size: 13px;
    line-height: 13px;
    color: $White;
    margin-right: 4px; }

.subheader__pay {
    display: flex;
    align-items: center; }

.pay__icon {
    margin-right: 10px;
    font-size: 15px;
    line-height: 15px;
    color: rgba($White,0.4); }

.pay__link {
    font-size: 13px;
    line-height: 13px;
    color: $LightGreen;
    transition: 0.4s;

    &:hover {
        text-decoration: underline;
        transition: 0.4s; } }

@media screen and ( max-width: 1240px ) and (min-width: 768px) {

    .subheader__content {
        display: grid;
        grid-gap: 10px;
        justify-content: center;
        grid-template-columns: 1fr 1fr; }

    .subheader__left {
        grid-column-start: 1;
        grid-column-end: 2; }

    .subheader__middle {
        grid-column-start: 1;
        grid-column-end: 3;
        order: -1; }

    .subheader__contacts {
        justify-content: center; }

    .list-subheader {
        grid-template-columns: max-content max-content; }

    .subheader__pay {
        justify-content: flex-end; }

    .subheader__right {
        grid-column-start: 2;
        grid-column-end: 3; }

    .list-subheader__link {
        font-size: 12px;
        line-height: 12px; } }

@media screen and ( max-width: 767px ) {

    .subheader__left {
        display: none; }

    .subheader__right {
        display: none; }

    .contact-subheader__title {
        font-size: 10px;
        line-height: 10px;
        text-align: center; }

    .contact-subheader {
        display: grid;
        grid-gap: 2px;
        padding-left: 30px; }

    .contacts__tel {
        text-align: center;
        font-size: 11px;
        line-height: 11px; }

    .subheader__content {
        display: grid;
        grid-template-columns: 1fr; }

    .subheader {
        position: relative; }

    .contact-subheader__icon {
        left: 0;
        top: 50%;
        position: absolute;
        transform: translateY(-50%); } }
