.request {
    background-color: $Blue;
    background-repeat: no-repeat;
    background-position: right center; }

.request__content {
    padding: 60px 0 55px 0;
    max-width: 870px; }

.request__title {
    font-size: 48px;
    line-height: 48px;
    font-weight: 700;
    color: $White;
    margin-bottom: 30px;
    text-transform: uppercase; }

.request__description {
    margin-bottom: 25px;
    color: $White;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700; }

.form__input {
    padding: 15px 20px;
    background-color: transparent;
    font-size: 14px;
    line-height: 14px;
    color: rgba($White, 0.76);
    border: 2px solid rgba($White, 0.76);
    border-radius: 4px;

    &::placeholder {
        color: rgba($White, 0.76); } }

.form__button {
    font-size: 14px;
    line-height: 14px;
    color: #121212;
    font-weight: bold;
    padding: 15px 47px;
    border: none;
    background-color: $White; }

.form {
    display: grid;
    grid-gap: 13px;
    grid-template-columns: 300px 300px max-content; }

@media screen and ( max-width: 1240px ) and (min-width: 768px) {

    .form {
        grid-template-columns: 1fr 1fr 0.5fr; } }

@media screen and ( max-width: 767px ) {

    .form {
        grid-template-columns: 1fr;
        grid-gap: 10px; }

    .form__button {
        justify-self: center;
        font-size: 9px;
        line-height: 9px;
        padding: 10px 30px; }

    .request__content {
        padding: 250px 0 15px 0; }

    .request--section {
        background-position: top center;

        .request__content {
            padding-top: 250px; } }

    .request {
        background-size: 100% 290px;
        background-position: top right;

        .request__content {
            padding-top: 320px; } }

    .request__title {
        font-size: 27px;
        line-height: 27px;
        text-align: center;
        margin-bottom: 15px; }

    .request__description {
        margin-bottom: 15px;
        font-size: 12px;
        line-height: 12px;
        text-align: center; }

    .form__input {
        padding: 8px 14px;
        font-size: 9px;
        line-height: 9px; } }

@media screen and ( max-width: 535px ) {

    .request {
        background-size: contain; }

    .request .request__content {
        padding-top: 240px; }

    .request--section {
        background-size: contain;
        .request__content {
            padding-top: 280px; } } }

@media screen and ( max-width: 400px ) {

    .request .request__content {
        padding-top: 180px; }

    .request--section {
        background-size: contain;
        .request__content {
            padding-top: 220px; } } }
