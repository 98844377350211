.about__wrapper {
    padding: 80px 0 90px 0; }

.about__content {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 520px 1fr; }

.about__left {
    border-radius: 5px;
    overflow: hidden; }

.about__title {
    margin-bottom: 50px;
    font-weight: normal; }

.about__right {
    padding-top: 35px;
    position: relative;

    &::after {
        content: "М";
        position: absolute;
        bottom: 15px;
        right: 90px;
        font-size: 362px;
        line-height: 312px;
        font-weight: bold;
        color: rgba($Blue, 0.13);
        pointer-events: none; } }

.about__subtitle {
    margin-bottom: 50px;
    font-size: 27px;
    line-height: 27px;
    color: $Black;

    span {
        text-transform: uppercase;
        display: block;
        font-size: 36px;
        line-height: 36px;
        font-weight: 700; } }

.about__text {
    font-size: 15px;
    line-height: 20px;
    color: rgba($Black, 0.6);

    p {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0; } } }

.about__subtitle--mobile {
    display: none; }

@media screen and ( max-width: 1240px ) and (min-width: 768px) {

    .about__content {
        grid-template-columns: 1fr; }

    .about__wrapper {
        padding-left: 20px;
        padding-right: 20px; } }


@media screen and ( max-width: 767px ) {

    .about__title {
        display: none; }

    .about__wrapper {
        padding: 30px 20px 0 20px; }

    .about__content {
        grid-template-columns: 1fr;
        grid-gap: 0;
        padding: 0 0 25px 0; }

    .about__subtitle--desktop {
        display: none; }

    .about__subtitle--mobile {
        display: block; }

    .about__image {
        max-width: 300px;
        margin: 0 auto 25px auto; }

    .about__subtitle {
        margin-bottom: 18px;
        text-align: center;
        font-size: 18px;
        line-height: 18px;
        span {
            font-size: 24px;
            line-height: 24px; } }

    .about__right {
        padding-top: 0;

        &::after {
            display: none; } }

    .about__text {
        p {
            font-size: 10px;
            line-height: 10px;
            margin-bottom: 15px; } } }
