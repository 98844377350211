.hot {
    background-color: #f0f8ff;
    border-bottom: 1px solid #e8e8e8;
    position: relative;

    &::before {
        content: "";
        height: 165px;
        width: 100%;
        background-color: $White;
        position: absolute;
        bottom: 0;
        left: 0;
        pointer-events: none;
        z-index: 0; } }

.hot__content {
    padding: 90px 0 85px 0;
    position: relative;
    z-index: 1; }

.hot__title {
    text-align: center;
    margin-bottom: 65px;
    font-weight: 700;
    font-size: 45px;
    line-height: 45px;
    letter-spacing: 2px;
    color: #313131;
    text-transform: uppercase; }

.rate__close {
    display: none;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 4;
    cursor: pointer; }

.rate__close--active {
    display: flex; }


.rate {
    overflow: hidden;
    position: relative;
    display: grid !important;
    grid-template-columns: 1fr;
    justify-content: space-between;
    max-width: 350px;
    min-height: 533px;
    border-radius: 4px;
    background-color: $White;
    box-shadow: 0 0 4px rgba($Black, 0.2);
    border-left: 10px solid $LightGreen;
    transition: 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation: fadeInFromNone 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    &:hover {
        transform: rotateY(0deg) translateY(-10px); } }

.rate__rotate {
    transition: 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform-style: preserve-3d;
    transform: rotateY(-180deg);
    animation: fadeIn 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    border-left-width: 0px;

    &:hover {
        transform: rotateY(-180deg) translateY(0px); }

    .rate__top, .rate__middle, .rate__bottom {
        transform: rotateY(-180deg);
        transition: 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        animation: fadeInFromNone 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards; } }



@keyframes fadeInFromNone {
    0% {

        opacity: 0; }

    100% {

        opacity: 1; } }


.rate__icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $LightGreen; }

.rate__top {
    display: grid;
    align-self: start;
    grid-gap: 10px;
    grid-template-columns: max-content 1fr;
    align-items: center;
    padding: 22px 20px 30px 20px;
    border-bottom: 1px solid #f0f0f0; }

.rate__title {
    display: grid;
    justify-content: center; }

.rate__label {
    margin-bottom: 8px;
    color: rgba($Black, 0.37);
    font-size: 14px;
    line-height: 14px; }

.rate__bottom {
    display: grid;
    align-self: end; }

.rate__name {
    font-size: 28px;
    line-height: 28px;
    color: #313131;
    text-transform: uppercase;
    font-weight: bold; }

.total {
    margin: 0 -20px 30px -20px;
    padding: 15px 30px 15px 30px;
    background-color: #ededed;
    display: flex;
    align-items: center;
    justify-content: space-between; }

.form__button {
    width: 100%; }

.total__title {
    font-size: 14px;
    line-height: 14px;
    color: #8e9595; }

.total__number {
    font-size: 36px;
    line-height: 36px;
    color: #313131;

    span {
        font-size: 18px;
        line-height: 18px; } }

.policy {
    text-align: center;
    color: rgba(0,0,0,.33);

    a {
        color: rgba(0,0,0,.33);

        &:hover {
            color: $Black; } } }

.rate__middle {
    min-height: 180px;
    display: grid;
    grid-gap: 30px;
    align-items: center;
    padding: 15px 20px 10px 20px; }

.rate__price {
    margin-bottom: 25px;
    font-size: 48px;
    line-height: 48px;
    color: #313131;
    text-align: center;

    span {
        font-size: 18px;
        line-height: 18px; } }

.rate__button {
    align-self: end;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: $White;
    padding: 15px 20px;
    background-color: $LightGreen;
    text-align: center;
    border-radius: 23px;
    cursor: pointer;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500; }

.rate__present {
    margin-bottom: 10px;
    text-align: center;
    font-size: 14px;
    line-height: 14px;
    color: rgba($Black, 0.33); }

.option-rate {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.option-rate__middle {
    width: 100%; }

.option-rate__input {
    margin-right: 10px; }

.option-rate__right {
    flex-shrink: 0; }

.option-rate__price {
    font-size: 32px;
    line-height: 32px;
    color: #313131; }

.option-rate__left {
    display: flex; }

.option-rate__input {
    cursor: pointer; }

.option-rate__title {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 4px;
    color: #313131;
    font-weight: 700; }

.option-rate__subtitle {
    font-size: 13px;
    line-height: 13px;
    color: rgba(0,0,0,.33); }


.speed {
    padding: 15px 0 10px 0;
    background-color: #ededed; }

.speed__limit {
    margin-bottom: 10px;
    font-size: 48px;
    line-height: 48px;
    color: #313131;
    text-align: center;

    span {
        font-size: 18px;
        line-height: 18px; } }

.speed__label {
    color: #8e9595;
    font-size: 14px;
    line-height: 14px;
    text-align: center; }

.rate__more {
    display: flex;
    align-items: center;
    padding: 0 30px 0 20px;
    height: 70px;
    position: relative;

    &::after {
        content: "";
        width: 8px;
        height: 8px;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0' y='0' width='15' height='15' viewBox='0 0 284.9 284.9' xml:space='preserve' fill='%23000000' enable-background='new 0 0 284.9 284.9'%3E%3Cpath d='M222.7 135.9l-133-133A9 9 0 0083 0a9 9 0 00-6.6 2.9L62.2 17a9 9 0 000 13.2l112.2 112.2L62.2 254.7a9 9 0 000 13.1l14.3 14.3a9 9 0 0013.2 0l133-133a9 9 0 000-13.2z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain; } }

.rate--green {
    border-color: $LightGreen;

    .rate__icon {
        background-color: $LightGreen; }

    .rate__button, .form__button {
        background-color: $Blue;
        color: $White; }

    .form__input {
        border-color: $Blue; } }

.rate--blue {
    border-color: $Blue;

    .rate__icon {
        background-color: $Blue; }

    .rate__button, .form__button {
        background-color: $LightGreen;
        color: $White; }

    .form__input {
        border-color: $LightGreen; } }

.rate--purple {
    border-color: $Purple;

    .rate__icon {
        background-color: $Orange; }

    .rate__button, .form__button {
        background-color: $Purple;
        color: $White; }

    .form__input {
        border-color: $Purple; } }


.hot__rates {
    padding: 0 35px;
    position: relative;

    .slick-arrow {
        position: absolute;
        font-size: 0;
        line-height: 0;
        color: transparent;
        cursor: pointer;
        background-color: transparent;
        top: 50%;
        width: 17px;
        height: 17px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0' y='0' width='15' height='15' viewBox='0 0 284.9 284.9' xml:space='preserve' fill='%23adb3b8' enable-background='new 0 0 284.9 284.9'%3E%3Cpath d='M222.7 135.9l-133-133A9 9 0 0083 0a9 9 0 00-6.6 2.9L62.2 17a9 9 0 000 13.2l112.2 112.2L62.2 254.7a9 9 0 000 13.1l14.3 14.3a9 9 0 0013.2 0l133-133a9 9 0 000-13.2z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain; }

    .slick-prev {
        left: 0;
        transform: translateY(-50%) rotateZ(180deg); }

    .slick-next {
        right: 20px; }

    .slick-track {
        padding: 10px 0;
        display: flex;
        justify-content: space-between; }

    .slick-active {
        &:nth-child(3n) {
            margin-right: 3px; }

        &:nth-child(2n) {
            margin-right: 3px; }

        &:nth-child(1n) {
            margin-right: 3px; } }


    .slick-slide {
        outline: none; } }

.rate__form {
    grid-template-columns: 1fr;

    .form__input {
        padding: 11px 20px;
        border-radius: 25px;
        color: rgba($Black, 0.6);

        &::placeholder {
            color: rgba($Black, 0.6); } } }

.rate__form--active {
    display: grid; }


.rate__option {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0; } }

.step-2 {
    display: none; }

.step-3 {
    display: none; }


@media screen and ( max-width: 1240px ) and (min-width: 768px) {

    .hot__rates {
        padding: 0 10px;

        .slick-next {

            right: -10px; }

        .slick-prev {
            left: -10px; } }

    .rate {
        box-shadow: 1px 1px 2px rgba($Black,0.1); } }

@media screen and ( max-width: 767px ) {

    .hot__content {
        padding: 30px 0 45px 0; }

    .hot__title {
        font-size: 27px;
        line-height: 27px;
        margin-bottom: 35px;
        text-align: center; }

    .rate__icon {
        width: 55px;
        height: 55px;

        img {
            transform: scale(0.76); } }

    .rate__label {
        font-size: 10px;
        line-height: 10px; }

    .rate__name {
        font-size: 20px;
        line-height: 20px; }

    .hot__rates {
        padding: 0 10px; }

    .hot__rates .slick-next {
        right: -15px; }

    .hot__rates .slick-prev {
        left: -15px; }

    .rate__top {
        padding: 15px 20px 20px 40px;
        grid-gap: 20px;
        width: 100%; }

    .rate__bottom {
        width: 100%; }

    .rate__title {
        justify-content: start; }

    .rate {
        border-width: 6px;
        justify-content: start;
        display: flex !important;
        flex-direction: column;
        min-height: 400px; }

    .total {
        margin: 0 0 20px 0;
        width: 100%; }

    .option-rate {
        width: 100%; }

    .rate__middle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 20px 20px 10px 20px; }

    .rate__form {
        margin-top: 0; }

    .rate__price {
        font-size: 34px;
        line-height: 34px;
        margin-bottom: 15px;

        span {
            font-size: 13px;
            line-height: 13px; } }

    .rate__button {
        width: 100%;
        padding: 10px 20px;
        max-width: 90%;
        margin: 0 auto 10px auto;
        font-size: 10px;
        line-height: 10px; }

    .rate__name {
        font-size: 15px !important;
        line-height: 15px !important; }

    .rate__present {
        font-size: 10px;
        line-height: 10px; }

    .rate__form {
        width: 100%;

        .form__button {
            width: 100%;
            padding: 13px 20px; } }

    .speed {
        padding: 12px 20px 10px 20px; }

    .speed__limit {
        font-size: 34px;
        line-height: 34px;
        margin-bottom: 10px;

        span {
            font-size: 13px;
            line-height: 13px; } }

    .rate__more {
        height: 50px;
        font-size: 10px;
        line-height: 10px; }

    .hot::before {
        height: 105px; }

    .step-2 {
        display: none; }

    .step-3 {
        display: none; } }
