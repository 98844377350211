.mainImage {
    background: $Purple;
    background: linear-gradient(180deg, $Purple 0%, $Blue 100%);
    position: relative; }

.slide-mainImage {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    min-height: 460px;
    position: relative; }

.slide-mainImage__left {
    padding-top: 75px;
    padding-bottom: 60px; }

.slide-mainImage__right {
    display: flex;
    justify-content: center;
    align-items: center; }

.slide-mainImage__title {
    margin: 0 0 50px 0;
    position: relative;
    z-index: 2;
    max-width: 510px;
    font-size: 72px;
    line-height: 72px;
    font-weight: 700;
    color: $White;
    text-transform: uppercase;
    letter-spacing: 3px; }

.slide-mainImage__image {
    max-width: 400px; }

.slide-mainImage__description {
    position: relative;
    z-index: 2;
    font-size: 20px;
    line-height: 20px;
    max-width: 530px;
    margin-bottom: 55px;
    color: $White;
    font-weight: 700; }

.slide-mainImage__button {
    position: relative;
    z-index: 2; }

.slick-dots {
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    display: grid;
    grid-auto-flow: column;
    grid-gap: 18px;

    li {
        display: flex;
        width: 10px;
        height: 10px;
        overflow: hidden;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        border: 2px solid $White;

        button {
            padding: 3px;
            width: 10px;
            height: 10px;
            font-size: 0;
            line-height: 0;
            color: transparent;
            border-radius: 50%;
            background-color: transparent;
            cursor: pointer; } }

    .slick-active {
        background-color: #fff;
        button {
            background-color: #fff; } } }

@media screen and ( max-width: 1240px ) and (min-width: 768px) {
    .slide-mainImage__title {
        font-size: 55px;
        line-height: 65px; } }

@media screen and ( max-width: 767px ) {

    .slide-mainImage {
        min-height: 300px;
        position: relative;
        grid-template-columns: 1fr;

        &:nth-child(2n) {
            margin-left: 2px; }

        &:nth-child(3n) {
            margin-left: 2px; }

        &:nth-child(4n) {
            margin-left: 2px; } }


    .slide-mainImage__left {
        padding-top: 60px;
        display: flex;
        flex-direction: column; }

    .slide-mainImage__title {
        margin-bottom: 12px;
        font-size: 34px;
        line-height: 34px;
        text-align: center; }

    .slide-mainImage__description {
        font-size: 12px;
        line-height: 12px;
        margin: 0 auto 15px auto;
        text-align: center;
        max-width: 165px; }

    .slide-mainImage__button {
        font-size: 8px;
        line-height: 8px;
        padding: 8px 18px;
        margin: 0 auto;
        background-color: $Blue;
        border: none; }

    .slide-mainImage__right {
        position: absolute;
        right: 2px;
        bottom: 2px; }

    .slide-mainImage__image {
        max-width: 150px; }

    .slick-dots {
        bottom: 19px; } }
