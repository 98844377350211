.subfooter {
    background-color: $DarkGrey;
    border-top: 1px solid #3b3b3b; }

.subfooter__content {
    padding: 21px 0;
    display: flex;
    align-items: center;
    justify-content: space-between; }

.subfooter__copyright {
    font-size: 14px;
    line-height: 14px;
    color: rgba($White, 0.55); }

.subfooter__socials {
    display: grid;
    grid-gap: 10px;
    grid-auto-flow: column;
    align-items: center; }

.socials__link {
    display: flex;
    align-items: center;
    filter: grayscale(100%); }

.footer {
    background-color: $DarkGrey; }

.footer__content {
    display: flex;
    justify-content: space-between;
    padding: 35px 0 25px 0; }

.footer__links {
    margin-right: 100px;
    flex-shrink: 0;
    width: 100%;
    max-width: 745px;
    display: flex;
    justify-content: space-between;

    li {
        &:first-child {
            a {
                font-weight: 700;
                cursor: default; } } }

    a {
        font-size: 15px;
        line-height: 30px;
        color: $White; } }

.contacts__connect {
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 13px;
    font-weight: 200;
    color: rgba($White, 0.4);
    transition: 0.4s;

    &:hover {
        color: $LightGreen;
        transition: 0.4s; } }

.contacts__tel {
    font-size: 27px;
    line-height: 27px;
    color: $White;
    transition: 0.4s;
    margin-bottom: 20px;

    &:hover {
        transition: 0.4s;
        color: $LightGreen; } }

.contacts__search {
    height: 55px;
    width: 100%;
    padding-left: 25px;
    padding-right: 50px;
    margin-bottom: 30px;
    border-radius: 10px;
    font-size: 15px;
    line-height: 15px;
    background-color: #484848;
    color: $White;

    &::placeholder {
        color: $White; }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    ::-webkit-search-results-decoration {
        display: none; } }


.contacts__mail {
    color: $LightGreen;
    font-size: 13px;
    line-height: 13px;
    text-decoration: underline;

    &:hover {
        text-decoration: none; } }

.search__wrapper {
    position: relative; }

.search__icon {
    position: absolute;
    right: 35px;
    bottom: 50%;
    transform: translateY(-50%);
    pointer-events: none; }

.footer__contacts {
    width: 100%;
    display: flex;
    flex-direction: column; }

.footer__contacts--mobile {
    display: none; }

@media screen and ( max-width: 1240px ) and (min-width: 768px) {

    .footer__content {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: 1fr; }

    .footer__links {
        margin-right: 0; }

    .footer__contacts {
        align-items: flex-start; } }

@media screen and ( max-width: 767px ) {

    .contacts__search {
        display: none; }

    .footer__links {
        margin-right: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px 40px; }

    .footer__contacts--mobile {
        display: flex;
        padding-top: 7px; }

    .footer__contacts--desktop {
        display: none; }

    .footer__links {
        li {
            a {
                font-size: 10px;
                line-height: 10px;
                font-weight: 200; } }
        &:first-child {
            a {
                font-weight: 400; } }

        .contacts__connect {
            color: #999999;
            margin-bottom: 5px;
            font-size: 8px;
            line-height: 8px; }

        .contacts__tel {
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 6px;
            text-align: left; }

        .contacts__mail {
            font-size: 8px;
            line-height: 8px;
            color: $LightGreen;
            font-weight: 200; } }

    .subfooter__content {
        padding: 20px 0 10px 0;
        display: grid;
        grid-gap: 15px;
        justify-content: center; }

    .socials {
        order: -1;
        grid-gap: 4px; }

    .social__item {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(0.8); }

    .subfooter__copyright {
        font-size: 8px;
        line-height: 8px;
        font-weight: 200; } }
